<template>
  <header ref="headerRef" class="relative bg-white sticky top-0 z-900 shadow-md">
    <div class="container bg-white justify-between items-center hidden lg:flex gap-2">
      <nuxt-link class="w-auto lg:w-2/14 h-auto" to="/">
        <v-image
            class="w-41 h-full object-contain"
            option_key="logo.global"></v-image>
      </nuxt-link>
      <div class="flex items-center">
        <GlobalMegaMenu/>
        <div class="btnDownload flex-none w-auto lg:ml-9">
          <button-primary
              :title="t('DOWNLOAD_IZI24')"
              :url="links_global?.download_app"
              :padding="'lg:px-6 xl:px-8 py-3 !capitalize'"
              class="lg:text-xs xl:text-base"
              :click-tracking-handler="trackClick"
          >
          </button-primary>
        </div>
        <!-- <div
            class="py-6 pl-6 gap-2px relative transition hover:text-secondary group cursor-pointer text-bw-03 flex items-center"
        >
            <component :is="checkCurrentLang" class="w-6 h-6"></component>

            <span class="i-custom-chevron-right transform rotate-90 w-4 h-4 text-black/60"></span>

            <div class="group-hover:block am-dropdown absolute top-full right-0 lg:pt-1.5 xl:pt-2 z-20 hidden">
                <div class="text-bw-03 bg-white border rounded-b-5x">
                    <nuxt-link
                        v-for="(lang, index) in lang_switch"
                        :key="index"
                        :to="lang?.url"
                        class="hover:bg-final-01 hover:text-primary flex items-center py-2 px-5 uppercase"
                    >
                        <component
                            :is="lang?.lang === 'en' ? 'IconsEng' : 'IconsVie'"
                            class="w-6 h-6 mr-2"
                        ></component>
                        <p :class="lang?.active ? 'text-primary' : 'text-black'" class="text-sm font-semibold">
                            {{ lang?.lang === 'en' ? 'En' : 'Vi' }}
                        </p>
                    </nuxt-link>
                </div>
            </div>
        </div> -->
      </div>
    </div>
  </header>
  <!-- MOBILE -->
  <header class="lg:hidden sticky top-0 z-100 bg-white header-mobile-shadow">
    <div class="flex-between px-4 py-3 bg-transparent pointer-events-auto">
      <nuxt-link class="w-40 md:w-45" to="/">
        <v-image class="w-full h-full object-contain" :width="250" option_key="logo.global"></v-image>
      </nuxt-link>
      <div class="flex-items gap-6">
        <!-- <div
            class="gap-5px relative transition hover:text-secondary group cursor-pointer text-bw-03 flex items-center"
        >
            <component :is="checkCurrentLang" class="w-6 h-6"></component>

            <span class="i-custom-chevron-right transform rotate-90 w-5 h-5 text-black/60"></span>

            <div class="group-hover:block am-dropdown absolute top-full right-0 pt-27px md:pt-8 z-10 hidden">
                <div class="text-bw-03 bg-white border rounded-b-5x">
                    <nuxt-link
                        v-for="(lang, index) in lang_switch"
                        :key="index"
                        :to="lang?.url"
                        class="hover:bg-final-01 hover:text-primary flex items-center py-2 px-5 uppercase"
                    >
                        <component
                            :is="lang?.lang === 'en' ? 'IconsEng' : 'IconsVie'"
                            class="w-6 h-6 mr-2"
                        ></component>
                        <p :class="lang?.active ? 'text-primary' : 'text-black'" class="text-sm font-semibold">
                            {{ lang?.lang === 'en' ? 'En' : 'Vi' }}
                        </p>
                    </nuxt-link>
                </div>
            </div>
        </div> -->

        <div class="cursor-pointer">
          <p class="i-custom-menu text-bw-15 w-6 h-6" @click="isOpenNav = !isOpenNav"></p>
        </div>
      </div>
    </div>
  </header>

  <GlobalMenuMobile @change-open-nav="setOpenNav" :is-open="isOpenNav"></GlobalMenuMobile>
</template>

<script setup lang="ts">
import {useGetLangCurrent} from '../../stores/useLanguageMenu'
import {
  DOWNLOAD_APP_BY_LINK_KEY, DOWNLOAD_EVENT_ID,
  useEventTracking
} from '../../composables/ackee/event'

const {t} = useI18n()

const links_global = inject('globalSettings')?.links[0]

const store = useGetLangCurrent()
const lang_switch = computed(() => store.lang_switch)
const isOpenNav = ref<boolean>(false)

function setOpenNav(value: boolean) {
  isOpenNav.value = value
}

watch(
    () => useRoute().fullPath,
    () => {
      isOpenNav.value = false
    }
)
const checkCurrentLang = computed(() => {
  if (lang_switch.value && lang_switch.value.length > 0) {
    const icon = lang_switch.value.find((item: any) => {
      if (item.lang_code == useRoute().params.langCode) {
        return item
      }
    })
    return icon.lang === 'en' ? 'IconsEng' : 'IconsVie'
  }
})
const trackClick = () => {
  useEventTracking(DOWNLOAD_EVENT_ID, DOWNLOAD_APP_BY_LINK_KEY);
};

const headerRef = ref<HTMLElement>()
</script>
<style scoped>
@media (max-width: 1279px) {
  .btnDownload {
    @apply !ml-2;
  }
}

.header-mobile-shadow {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.3);
}
</style>
